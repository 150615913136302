import { CallTypeEnum, CallStatusEnum, CallAnsweredByEnum } from './enums';

export const callTypeTranslation = {
  [CallTypeEnum.OUTBOUND]: 'Saliente',
  [CallTypeEnum.INBOUND]: 'Entrante',
};

export const callStatusTranslation = {
  [CallStatusEnum.CREATED]: 'Creada',
  [CallStatusEnum.QUEUED]: 'En cola',
  [CallStatusEnum.RINGING]: 'Timbrando',
  [CallStatusEnum.IN_PROGRESS]: 'En progreso',
  [CallStatusEnum.FORWARDING]: 'Transfiriendo',
  [CallStatusEnum.ENDED]: 'Finalizada',
  [CallStatusEnum.BUSY]: 'Ocupado',
  [CallStatusEnum.NO_ANSWER]: 'Sin respuesta',
  [CallStatusEnum.FAILED]: 'Fallida',
};

export const callAnsweredByTranslation = {
  [CallAnsweredByEnum.HUMAN]: 'Humano',
  [CallAnsweredByEnum.MACHINE]: 'Máquina',
  [CallAnsweredByEnum.UNKNOWN]: 'Indeterminado',
};
